
import { defineAsyncComponent, defineComponent } from 'vue'
import { ClientActions } from '@/store/modules/clients/actions'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { Order } from '@/shared/interfaces/order.interface'

export default defineComponent({
  name: 'OrderList',
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    CreateOrderModal: defineAsyncComponent(() => import('@/views/account/orders/components/OrderCreateModal.vue')),
    BulkOrderCreateModal: defineAsyncComponent(() => import('@/views/account/orders/components/BulkOrderCreateModal.vue')),
    BulkOrderSelectButton: defineAsyncComponent(() => import('@/views/account/orders/components/BulkOrderSelectButton.vue'))
  },
  data () {
    return {
      tableFields: [
        { id: 'orderId', name: 'ID', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'statusText', name: 'Status', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'createdAt', name: 'Date', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'orderTotal', name: 'Amount', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'userAddress', name: 'Customer Address', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'customerPhone', name: 'Customer Phone', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'driverText', name: 'Driver', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'vehicleTypeName', name: 'Vehicle', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'canceledErpSyncStatus', name: 'Canceled ERP Sync Status', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'deliveredErpSyncStatus', name: 'Delivered ERP Sync Status', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'pickupErpSyncStatus', name: 'Pickup ERP Sync Status', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'settlementStatus', name: 'Settlement Status', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'erpnextMerchant', name: 'Erp Next Merchant', width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'orderBinNumber', name: 'Bin Number', width: '18%', sortable: false, currentSort: false, sort: null }
      ],
      showBulkCreateOrderModal: false,
      showCreateOrderModal: false,
      showFilterModal: false,
      filters: [
        {
          name: this.$t('filters.orderBy'),
          model: '',
          type: 'order',
          data: [
            { id: 'created_at', value: this.$t('filters.createdAt') },
            { id: 'delay_time', value: this.$t('filters.delayTime') }
          ]
        },
        {
          name: this.$t('filters.isDelayed'),
          model: 'isDelayed',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.currentAssigned'),
          model: 'currentAssigned',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        { name: this.$t('filters.createdAt'), model: 'createdAt', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.assigmentType'),
          model: 'assigmentType',
          type: 'radio',
          data: [
            { id: 'manual', label: this.$t('filters.manual') },
            { id: 'auto', label: this.$t('filters.auto') },
            { id: 'all', label: this.$t('filters.all') }
          ]
        },
        {
          name: this.$t('filters.orderPriority'),
          model: 'priority',
          type: 'radio',
          data: [
            { id: 'all', label: this.$t('filters.all') },
            { id: 'normal', label: this.$t('filters.normal') },
            { id: 'prime', label: this.$t('filters.prime') }
          ]
        },
        {
          name: this.$t('filters.settlementStatus'),
          model: 'settlementStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.settlementStatus.init') },
            { id: 'requested', label: this.$t('orders.settlementStatus.requested') },
            { id: 'approved', label: this.$t('orders.settlementStatus.approved') },
            { id: 'rejected', label: this.$t('orders.settlementStatus.rejected') }
          ]
        },
        {
          name: this.$t('filters.pickupErpSyncStatus'),
          model: 'pickupErpSyncStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.erpSyncStatus.init') },
            { id: 'pending', label: this.$t('orders.erpSyncStatus.pending') },
            { id: 'synced', label: this.$t('orders.erpSyncStatus.synced') },
            { id: 'failed', label: this.$t('orders.erpSyncStatus.failed') }
          ]
        },
        {
          name: this.$t('filters.deliveredErpSyncStatus'),
          model: 'deliveredErpSyncStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.erpSyncStatus.init') },
            { id: 'pending', label: this.$t('orders.erpSyncStatus.pending') },
            { id: 'synced', label: this.$t('orders.erpSyncStatus.synced') },
            { id: 'failed', label: this.$t('orders.erpSyncStatus.failed') }
          ]
        },
        {
          name: this.$t('filters.canceledErpSyncStatus'),
          model: 'canceledErpSyncStatus',
          type: 'radio',
          data: [
            { id: 'init', label: this.$t('orders.erpSyncStatus.init') },
            { id: 'pending', label: this.$t('orders.erpSyncStatus.pending') },
            { id: 'synced', label: this.$t('orders.erpSyncStatus.synced') },
            { id: 'failed', label: this.$t('orders.erpSyncStatus.failed') }
          ]
        }
      ]
    }
  },
  methods: {
    init (route: any = null) {
      const val = route || this.$route
      let params = JSON.parse(JSON.stringify(val.query))
      params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
      this.$store.dispatch(`clients/${ClientActions.FETCH_CLIENT_ORDERS}`, params)
    },
    onOrderCreatedHandler () {
      this.showBulkCreateOrderModal = false
      this.showCreateOrderModal = false
      this.init()
    }
  },
  computed: {
    orders (): Order[] {
      return this.$store.getters['clients/getOrders'].map((o: Order) => {
        return {
          ...o,
          statusText: o.orderStatus.value,
          customerPhone: o.customerAddressDetails?.address_phone ?? '',
          vehicleTypeName: o.vehicleType?.value ?? '',
          driverText: (o.driver?.driverInfo?.firstName ?? '') + ' ' + (o.driver?.driverInfo?.lastName ?? '')
        }
      })
    },
    pagination (): PaginationInterface {
      return this.$store.getters['clients/getOrderPagination']
    },
    loading (): boolean {
      return this.$store.getters['clients/getLoading']
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'ClientOrders') {
          this.init(val)
        }
      }
    }
  }
})
